import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Segment, Header, Button, Modal, Table, Icon } from "semantic-ui-react";
import AllocatedInvestigator from "../../../../../../models/AllocatedInvestigator";
import FileLabel from "../../../../../common/FileLabel";
import UploadEvidenceForm from "./UploadEvidenceForm";
import FinishInvestigationForm from "./FinishInvestigationForm";
import URLResource from "../../../../../../models/URLResource";
import Remove from "../../../../../common/Remove";
import CaseRepository from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import { toast } from "../../../../../common/Toast";
import RequirePermission from "../../../../../base/RequirePermission";
import permissions from "../../../../../../common/permissions";
import FilesPopup from "../../FilesPopup";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";
import APIResponseModel from "../../../../../../common/repository/APIResponseModel";
import { access } from "fs";
import LocalStorage from "../../../../../../lib/LocalStorage";
import config from "../../../../../../common/repository/config";

interface InvestigatorCardProps {

  model: AllocatedInvestigator;
  updateModel?(model: AllocatedInvestigator): void;
  removeModel?(model: AllocatedInvestigator): void;
  enableReinvestigation?(model: AllocatedInvestigator): void;
}
function InvestigatorCard(props: InvestigatorCardProps) {

  const portalRoot = document.getElementsByTagName("body")[0];
  const caseRepo = new CaseRepository();
  const { model, updateModel } = props;
  const investigator = model.getInvestigator();
  let [evidence, setEvidence] = useState(model.getEvidence);
  let [removedEvidence, setRemoveEvidence] = useState(model.getRemovedEvidence);
  const [downloading, setDownloading] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]); // Track selected indices
  const updateRemoveEvidence = (e: URLResource[], index: any) => {

    setRemoveEvidence((prev: any) => {
      const updatedArray = [...prev]; // Copy the previous state
      updatedArray[index] = { // Update the item at the specified index
        ...updatedArray[index],
        document: e
      };
      return updatedArray;
    });
  };
  const handleBulkDownload = async (allocationId: string) => {
    setDownloading(true); // Set downloading state to true
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN); // Retrieve token from localStorage
      if (!token) {
        console.error('Authentication token is missing.');
        return;
      }
      const response = await fetch(`${config.apiBaseURL}/case/allocate/bulk-download/${allocationId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({
          evidenceUrls: evidence.map((item) => item.getURL()), // Adjust based on your data structure
        }),
      });
      if (!response.ok) {
        throw new Error(`Failed to download: ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const disposition = response.headers.get('Content-Disposition');
      console.log("Disposition Header", disposition);
      let fileName = `Evidence ${allocationId}`; //default fileName
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const parts = disposition.split(";");
        for (let part of parts) {
          part = part.trim();
          if (part.startsWith('filename=')) {
            fileName = part.substring(9).replace(/['"]/g, ''); // Remove quotes if present
          }
          // const matches = fileNameRegex.exec(disposition);
          // if (matches != null && matches[1]) {
          //   fileName = matches[1].replace(/['"]/g, '');
          // }
        }
      }
      console.log('Content-Type header:', fileName);
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      console.log('ZIP file downloaded successfully');
    } catch (error) {
      console.error('Error in handleBulkDownload:', error);
    } finally {
      setDownloading(false);
    }
  };
  const updateEvidence = (e: URLResource[]) => {
    setEvidence(e);
  };
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [uploadEvidenceModalOpen, setUploadEvidenceModalOpen] = useState(false);
  const [finishInvestigationModalOpen, setFinishInvestigationModalOpen] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [openIndex, setOpenIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    // Load selected indices from localStorage if available
    const savedIndices = localStorage.getItem('selectedFileIndices');
    if (savedIndices) {
      setSelectedIndices(JSON.parse(savedIndices));
    }
  }, []);

  useEffect(() => {
    // Save selected indices to localStorage
    if (selectedIndices.length > 0) {
      localStorage.setItem('selectedFileIndices', JSON.stringify(selectedIndices));
    }
  }, [selectedIndices]);
  const handleFileLabelClick = (index: number) => {
    setSelectedIndices((prevIndices: number[]) => {
      if (prevIndices.includes(index)) {
        // Deselect if already selected
        return prevIndices.filter(i => i !== index);
      } else {
        // Select the new item
        return [...prevIndices, index];
      }
    });
  };
  useEffect(() => {
    effectRemoveInvestigator();
  }, [isRemoving]);
  let uploadEvidence: React.ReactNode | null = null;
  let finishInvestigation: React.ReactNode | null = null;
  let reopenInvestigation: React.ReactNode | null = null;
  if (model.getActive()) {
    uploadEvidence = (
      <Modal
        open={uploadEvidenceModalOpen}
        onClose={() => setUploadEvidenceModalOpen(false)}
        size="mini"
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
        trigger={
          <Button
            primary
            disabled={!model.getActive()}
            onClick={() => setUploadEvidenceModalOpen(true)}
          >
            Upload Evidence
          </Button>
        }
      >
        <Modal.Header>Upload Evidence</Modal.Header>
        <Modal.Content>
          <UploadEvidenceForm
            setEvidence={setEvidence}
            model={model}
            onSuccess={(model) => {
              setUploadEvidenceModalOpen(false);
              if (updateModel) updateModel(model);
            }}
          />
        </Modal.Content>
      </Modal>
    );
    finishInvestigation = (
      <Modal
        open={finishInvestigationModalOpen}
        onClose={() => setFinishInvestigationModalOpen(false)}
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
        trigger={
          <Button
            primary
            disabled={!model.getActive()}
            onClick={() => setFinishInvestigationModalOpen(true)}
          >
            Finish Investigation
          </Button>
        }
      >
        <Modal.Content>
          <FinishInvestigationForm
            model={props.model}
            onSuccess={(model) => {
              setFinishInvestigationModalOpen(false);
              if (updateModel) updateModel(model);
            }}
          />
        </Modal.Content>
      </Modal>
    );
  } else {
    reopenInvestigation = (
      <Button
        primary
        onClick={() =>
          props.enableReinvestigation
            ? props.enableReinvestigation(model)
            : null
        }
      >
        Re-Open Investigation
      </Button>
    );
  }

  return (
    <Segment>
      <div>
        {/* section */}
        <div>
          <Header as="h4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {investigator.getName()}
                {renderSubHeader(model)}
              </div>

              {/* section */}
              <div>
                <Button primary onClick={() => handleBulkDownload(props.model.getId())} disabled={downloading}>
                  {downloading ? (
                    <Icon loading name='spinner' />
                  ) : (
                    <Icon name='download' />
                  )}
                  Download Evidence
                </Button>
                {/* upload evidence form */}
                {uploadEvidence}
                {/* upload evidence form */}

                {/* finish investigation form */}
                {finishInvestigation}
                {/* finish investigation form */}


                {/*Reopen investigation */}
                {reopenInvestigation}
                {/*Reopen investigation */}

                {/* remove investigator */}
                <RequirePermission
                  permission={permissions.Case.Investigator.remove}
                >
                  <Remove.Modal
                    open={isOpenRemoveModal}
                    onClose={() => setIsOpenRemoveModal(false)}
                    trigger={
                      <Button
                        negative
                        onClick={() => setIsOpenRemoveModal(true)}
                      >
                        Remove Investigator
                        {/* upload evidence form */}
                      </Button>
                    }
                  >

                    <Remove
                      loading={isRemoving}
                      onCancel={() => setIsOpenRemoveModal(false)}
                      onRemove={() => setIsRemoving(true)}
                    />
                  </Remove.Modal>

                  {/* <Button
                  primary
                    negative
                    onClick={() => handleBulkDownload(model.getId())} 
                  >
                     Download Evidence
                  </Button> */}
                </RequirePermission>
                {/* remove investigator */}
              </div>
              {/* section */}
            </div>
          </Header>
          {/*{renderEvidenceList(evidence)}*/}

         
          <FileLabel.Group horizontal >
          {evidence.map((it, index) => {
            const isSelected = selectedIndices.includes(index);
            const fileLabelStyle = {
              border:isSelected ? "3px solid #edb964": '#00b5ad',
              borderRadius:"3px",
              cursor: 'pointer',
              paddingRight: isSelected ?"2px":"0px",
              paddingLeft: isSelected ?"5px":"0px",
              paddingTop: isSelected ?"2px":"4px",
              backgroundColor:isSelected ? "rgb(237, 185, 100)": '#00b5ad',
              marginRight:"8px",
              marginBottom:"5px"
             
            };

            return (
              <div
                key={index}
                style={fileLabelStyle}
                onClick={() => handleFileLabelClick(index)}
              >
                <FileLabel
                  title={it.getFullName().slice(0, 30)}
                  link={it.getURL()}
                  removable={evidence.length > 1}
                  remove={async () => {
                    const result = await caseRepo.removeInvestigationDocument({
                      allocation_id: model.getId(),
                      document_url: it.getURL(),
                    });
                    if (result instanceof Result.Success) {
                      const c = result.data;
                      let updatedData: URLResource[] = [];
                      c.items.forEach((item) => {
                        if (model.getId() === item.getId()) {
                          updatedData = item.getEvidence();
                        }
                      });
                      updateEvidence(updatedData);
                      toast.success("Document removed successfully");
                      return true;
                    } else {
                      const message = result.message || "Something went wrong";
                      toast.error(message);
                      return false;
                    }
                  }}
                />
              </div>
            );
          })}
        </FileLabel.Group>

          <div >
            <br />
            <h4>Removed Evidence :</h4>&nbsp;&nbsp;&nbsp;
            <div>
              {console.log(removedEvidence, "removedEvidence")}
              {removedEvidence?.map((item, index) => (
                <span key={index}>
                  {item.document.length === 0 ? `No ${item.type}` : `View ${item.type}`}
                  <FileLabel.Group horizontal>
                    {item?.document?.map((it, Fileindex) => {
                      let removable = true;
                      // if (evidence.length === 1)
                      //     removable = false
                      return (
                        <div key={Fileindex} style={{ border: "3px solid rgb(255 0 0 / 92%)", borderRadius: "50px", padding: " 2px 0px 0px 2px", marginLeft: "3px" }}>
                          <FileLabel
                            // color='red'
                            title={it.getFullName().slice(0, 30)}
                            link={it.getURL()}
                            removable={removable}
                            remove={async () => {
                              const result = await caseRepo.removeInvestigationDocument({
                                allocation_id: model.getId(),
                                document_url: it.getURL(),
                              });
                              if (result instanceof Result.Success) {
                                const c = result.data;
                                let updatedData: URLResource[] = [];
                                c.items.forEach((it) => {
                                  if (model.getId() == it.getId()) {
                                    setRemoveEvidence(it.getRemovedEvidence())
                                    updatedData = it.getEvidence();
                                  }
                                });
                                // console.log(updatedData, "updatedData")
                                // updateRemoveEvidence(updatedData, index);
                                toast.success("Document removed successfully");
                                return true;
                              } else {
                                const message = result.message || "Something went wrong";
                                toast.error(message);
                                return false;
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </FileLabel.Group>
                </span>
              ))}
            </div>
          </div>
        </div>
        {/* section */}
      </div>
    </Segment>
  );

  async function effectRemoveInvestigator() {
    if (!isRemoving) return;

    const result = await caseRepo.removeAllocatedInvestigator(model.getId());
    if (result instanceof Result.Success) {
      toast.success("Successfully removed Investigator");
      if (props.removeModel) props.removeModel(model);
    } else {
      const message = result.message || "Something went wrong";
      toast.error(message);
    }

    setIsRemoving(false);
    setIsOpenRemoveModal(false);
  }
}

const renderEvidenceList = (evidence: URLResource[]) => {
  if (evidence.length === 0) {
    return "No evidence uploaded yet";
  } else {
    return (
      <FileLabel.Group horizontal>
        {evidence.map((it) => (
          <FileLabel title={it.getFullName()} link={it.getURL()} />
        ))}
      </FileLabel.Group>

    );
  }
};

const renderSubHeader = (model: AllocatedInvestigator) => {
  if (model.getActive()) return null;

  return (
    <Header.Subheader>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ color: "green" }}>Investigation Finished</div>
        <div style={{ margin: "0 16px" }}>{model.getOutcome()}</div>
        <div>{model.getCasePortion()}</div>
      </div>
    </Header.Subheader>
  );
};

export default InvestigatorCard;



