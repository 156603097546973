import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Segment,
  Header,
  Rating,
  TextArea,
  Form,
  Divider,
  Button,
  Grid,
} from "semantic-ui-react";
import FilesPopup from "../../FilesPopup";
import AllocatedClaimAnalyst from "../../../../../../models/AllocatedClaimAnalyst";
import AllocatedInvestigator from "../../../../../../models/AllocatedInvestigator";
import CaseRepository from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import CompleteCase from "../../../../../../models/CompleteCase";
import moment from "moment";
export interface InvestigationCardInputValue {
  id: string;
  claimAnalystId: string;
  allocationHygiene: number;
  reportQuality: number;
  outcome: number;
  tat: number;
  feedback: string;
  investigators: string[];
}

interface Props {
  model: AllocatedClaimAnalyst;
  value: InvestigationCardInputValue;
  onChange?(value: InvestigationCardInputValue): void;
  newData: CompleteCase;
}

const InvestigationCard = (props: Props) => {
  const { model, newData } = props;
  const portalRoot = document.getElementsByTagName("body")[0];
  const repo = new CaseRepository();
  const [lastUser, setLastUser] = useState<any>([])

  useEffect(() => {
    getLastUser()
  }, [])

  async function getLastUser() {
    const result = await repo.getLastActivityUser(newData.getId());
    console.log(result?.items)
    setLastUser(result?.items)
    if (result instanceof Result.Success) {
      setLastUser(result.data.items);
    }
  }


  return (
    <Segment>
      {/* <Header as="h3">{model.getClaimAnalyst().getName()}</Header> */}
      <Header as="h3">
        {/* {model.getClaimAnalyst().getName()} */}
        {`State: ${lastUser?.state}`}<br />
        {lastUser?.name}<br />
        {moment(lastUser?.date).format("LL")}
      </Header>
      {/* section */}
      {model.getAllocatedInvestigators().map((it) => (
        <>
          <InvestigatorListItem model={it} portalRoot={portalRoot} />
          <Divider />
        </>
      ))}
      {/* section */}
    </Segment>
  );
};

interface InvestigatorListItemProps {
  model: AllocatedInvestigator;
  portalRoot: HTMLElement;
}

const InvestigatorListItem = (props: InvestigatorListItemProps) => {
  const { model, portalRoot } = props;
  const [open, setOpen] = useState(false);
  const [documentReceiptVisible, setDocumentReceiptVisible] = useState(false);
  const [expenseReceiptVisible, setExpenseReceiptVisible] = useState(false);
  const [approvedReceiptVisible, setApprovedReceiptVisible] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const evidence = model.getEvidence();
  const documentReceipts = model.getDocumentExpenseReceipt();
  const extraExpenseReceipts = model.getExtraExpenseReceipt();
  const approvedExpenseReceipts = model.getApprovedExpenseReceipt();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header as="h5">{model.getInvestigator().getName()}</Header>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Button
          onClick={(e) => {
            setX(e.clientX);
            setY(e.clientY);
            setOpen(true);
          }}
          disabled={open || evidence.length === 0}
          primary
        >
          {evidence.length === 0 ? "No Evidence" : "View Evidence"}
        </Button>

        <div style={{ margin: "0 24px", fontSize: 16 }}>
          Outcome: <strong>{model.getOutcome()}</strong>
        </div>

        <div style={{ fontSize: 16 }}>
          Portion: <strong>{model.getCasePortion()}</strong>
        </div>

        <div style={{ margin: "0 24px", fontSize: 16 }}>
          Allowance: <strong>{model.getAllowance()}₨</strong>
        </div>

        <div style={{ fontSize: 16 }}>
          Location:{" "}
          <strong>
            {model.getInvestigationLocation()?.getDistrict()},{" "}
            {model.getInvestigationLocation()?.getState()}
          </strong>
        </div>

        <div style={{ margin: "0 24px", fontSize: 16 }}>
          Document Charges: <strong>{model.getDocumentExpense()}₨</strong>
          <Button
            style={{ width: "177px" }}
            onClick={(e) => {
              setX(e.clientX);
              setY(e.clientY);
              setDocumentReceiptVisible(true);
            }}
            disabled={documentReceiptVisible || documentReceipts.length === 0}
            primary
          >
            {documentReceipts.length === 0
              ? "No Document Receipts"
              : "View Document Receipts"}
          </Button>
        </div>

        <div style={{ fontSize: 16 }}>
          Extra Charges(Investigator):{" "}
          <strong>{model.getExtraExpense()}₨</strong>
          <Button
            style={{ width: "177px" }}
            onClick={(e) => {
              setX(e.clientX);
              setY(e.clientY);
              setExpenseReceiptVisible(true);
            }}
            disabled={
              expenseReceiptVisible || extraExpenseReceipts.length === 0
            }
            primary
          >
            {extraExpenseReceipts.length === 0
              ? "No Extra Document Receipts"
              : "View Extra Document" + " Receipts"}
          </Button>
        </div>

        <div style={{ margin: "0 24px", fontSize: 16 }}>
          Approved Charges(Invoice):{" "}
          <strong>{model.getApprovedExpense()}₨</strong>
          <Button
            style={{ width: "177px" }}
            onClick={(e) => {
              setX(e.clientX);
              setY(e.clientY);
              setApprovedReceiptVisible(true);
            }}
            disabled={
              approvedReceiptVisible || approvedExpenseReceipts.length === 0
            }
            primary
          >
            {approvedExpenseReceipts.length === 0
              ? "No Approved Document Receipts"
              : "View Approved" + " Document" + " Receipts"}
          </Button>
        </div>
      </div>
      {open &&
        ReactDOM.createPortal(
          <FilesPopup
            x={x}
            y={y}
            onClose={() => setOpen(false)}
            title={`Evidence By - ${model.getInvestigator().getName()}`}
            files={model.getEvidence()}
            allocationId={model.getId()}     />,
          portalRoot
        )}
      {documentReceiptVisible &&
        ReactDOM.createPortal(
          <FilesPopup
            x={x}
            y={y}
            onClose={() => setDocumentReceiptVisible(false)}
            title={`Document Receipts`}
            files={model.getDocumentExpenseReceipt()} allocationId={model.getId()}       />,
          portalRoot
        )}
      {expenseReceiptVisible &&
        ReactDOM.createPortal(
          <FilesPopup
            x={x}
            y={y}
            onClose={() => setExpenseReceiptVisible(false)}
            title={`Document Extra Expense Receipts`}
            files={model.getExtraExpenseReceipt()} allocationId={model.getId()}   />,
          portalRoot
        )}
      {approvedReceiptVisible &&
        ReactDOM.createPortal(
          <FilesPopup
            x={x}
            y={y}
            onClose={() => setApprovedReceiptVisible(false)}
            title={`Approved Expense Receipts`}
            files={model.getApprovedExpenseReceipt()} allocationId={model.getId()}   />,
          portalRoot
        )}
    </div>
  );
};

InvestigationCard.initialValue = {
  id: "",
  claimAnalystId: "",
  allocationHygiene: 0,
  reportQuality: 0,
  outcome: 0,
  tat: 0,
  feedback: "",
  investigators: [],
} as InvestigationCardInputValue;

export default InvestigationCard;
