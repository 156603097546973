import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { Icon } from "semantic-ui-react";
import FileLabel from "../../../common/FileLabel";
import URLResource from "../../../../models/URLResource";
import LocalStorage from "../../../../lib/LocalStorage";
import config from "../../../../common/repository/config"



const Header = styled.div`
   width: 300px;
  padding: 8px 16px;
  cursor: all-scroll;
  background-color: #031b4d;
  border-radius: 8px 8px 0 0;
  color: white;
`;

const Body = styled.div`
  width: 300px;
  min-height: 275px;
  padding: 16px;
  background: white;
  border-radius: 0 0 8px 8px;
`;

const Container = styled.div`
  position: absolute;
  border-radius: 8px;
  box-shadow: 1px 2px 20px 8px rgba(0, 0, 0, 0.09),
    -1px -2px 20px 8px rgba(0, 0, 0, 0.09);
  z-index: 10000;
`;

const Button = styled.button`
 width: 230px;
 margin:10px;
  background-color: rgba(3, 27, 77, 0.8);
  color: white;
  padding: 15px 26px;
  border-radius: 38px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: #1e3a8a;
  }

  span {
    display: inline-block;
    width: 100%;
    text-align: center;
    transition: opacity 0.3s ease;
    
  }
`;

const LoadingContainer = styled.div`
 width: 230px;
 margin:10px;
 background-color: rgba(3, 27, 77, 0.8);
opacity: 0.5;
  color: white;
  padding: 15px 26px;
  border-radius: 38px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #1e3a8a;
  }
`;
const LoadingSpinner = styled.div`
   width: 24px;
  height: 24px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #f25a41;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
`;
const LoadingText = styled.p`
  margin-left: 10px;
  color: white;
`;

interface Props {
  x?: number;
  y?: number;
  title?: string;
  onClose?(): void;
  files: URLResource[];
  allocationId: string;
}

const FilesPopup: React.FC<Props> = ({
  x = 0,
  y = 0,
  title = "Files",
  onClose = () => { },
  files,
  allocationId,
}) => {
  const [loading, setLoading] = useState(false);

  const handleBulkDownload = async () => {
    setLoading(true);

    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN) || "";
      const evidenceUrls = files.map((item) => item.getURL());
      const url = `${config.apiBaseURL}/case/allocate/bulk-download/${allocationId}`;

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      if (token) {
        headers.append("x-access-token", token);
      }

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          evidenceUrls: evidenceUrls,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to download: ${response.statusText}`);
      }

      const blob = await response.blob();
      const disposition = response.headers.get("Content-Disposition");
      let fileName = `Evidence_${allocationId}.zip`; // Default filename

      if (disposition && disposition.indexOf("attachment") !== -1) {
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = regex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }

      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);

    } catch (error) {
      console.error("Error in handleBulkDownload:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ position: "absolute", top: `${y}px`, left: `${x}px` }}>
      <motion.div drag dragMomentum={false} animate={{ scale: 1 }} initial={{ scale: 0 }}>
        <Container>
          <Header>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{title}</div>
              <Icon
                name="x"
                size="large"
                color="red"
                style={{ cursor: "pointer" }}
                onClick={onClose}
              />
            </div>
          </Header>
          <Body style={{ wordBreak: "break-all" }}>
            <FileLabel.Group horizontal>
              {files.map((file, index) => (
                <FileLabel key={index} title={file.getFullName()} link={file.getURL()} />
              ))}
            </FileLabel.Group>
            {loading ? (
              <LoadingContainer>
                <LoadingSpinner />
                <LoadingText>Downloading...</LoadingText>
              </LoadingContainer>
            ) : (
              <Button onClick={handleBulkDownload}>Download Evidence</Button>
            )}
          </Body>
        </Container>
      </motion.div>
    </div>
  );
};

FilesPopup.defaultProps = {
  x: 0,
  y: 0,
  title: "Files",
  onClose: () => { },
};

export default FilesPopup;
