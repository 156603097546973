import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Segment,
  Header,
  Modal,
  Button,
  Icon,
  Rating,
  Form,
  TextArea,
  Label,
} from "semantic-ui-react";
import CompleteCase from "../../../../../../models/CompleteCase";
import InvestigationCard, {
  InvestigationCardInputValue,
} from "./InvestigationCard";
import GradingCard, { GradingCardInputValue } from "./GradingCard";
import AllocatedClaimAnalyst from "../../../../../../models/AllocatedClaimAnalyst";
import CaseRepository, {
  PerformGradingRequestObject,
  PerformQualityCheckRequestObject,
} from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import { toast } from "../../../../../common/Toast";
import QCData from "../../../../../../models/QCData";
import FilesPopup from "../../FilesPopup";
import URLResource from "../../../../../../models/URLResource";
import RequirePermission from "../../../../../base/RequirePermission";
import permissions from "../../../../../../common/permissions";

interface Props {
  model: CompleteCase;
  updateModel?(c: CompleteCase): void;
}

interface State {
  loading: boolean;
  pushbackModalOpen: boolean;
  repudiationEvidencePopupOpen: boolean;
  data: QCData | null;
  values: InvestigationCardInputValue[];
  valuesGrading: GradingCardInputValue[];
}

class ActionGrading extends Component<Props, State> {
  private caseRepo = new CaseRepository();
  private portalRoot: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      pushbackModalOpen: false,
      repudiationEvidencePopupOpen: false,
      data: null,
      values: [],
      valuesGrading: [],
    };
  }

  componentDidMount() {
    this.getData();
    this.portalRoot = document.getElementsByTagName("body")[0];
  }

  getData = () => {
    this.setState({ loading: true }, async () => {
      const { model } = this.props;
      const result = await this.caseRepo.getQCData({ case_id: model.getId() });

      if (result instanceof Result.Success) {
        const data = result.data;
        const values = data
          .getAllocations()
          .map((it, i) => this.mapAllocatedClaimAnalystToValue(`${i}`, it));
        this.setState({ loading: false, values, data: data });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  mapAllocatedClaimAnalystToValue = (
    fakeId: string,
    allocatedClaimAnalyst: AllocatedClaimAnalyst
  ) => {
    const value = { ...InvestigationCard.initialValue };

    value.id = fakeId;
    value.claimAnalystId = allocatedClaimAnalyst.getClaimAnalyst().getId();
    const investigators = allocatedClaimAnalyst
      .getAllocatedInvestigators()
      .map((it) => {
        const id = it.getInvestigator().getId();
        const allocationId = it.getId();
        const casePortion = it.getCasePortion();
        const investigationOutcome = it.getOutcome();
        const investigationLocation = it.getInvestigationLocation();
        const allowance = it.getAllowance();
        const documentExpense = it.getDocumentExpense();
        const extraExpense = it.getExtraExpense();
        const approvedExpense = it.getApprovedExpense();

        let capping = "";
        if (casePortion !== null) capping = casePortion.toString();

        let outcome = "";
        if (investigationOutcome !== null) outcome = investigationOutcome;
        return {
          id,
          allocationId,
          capping,
          outcome,
          investigationLocation,
          allowance,
          documentExpense,
          extraExpense,
          approvedExpense,
        };
      });

    value.investigators = investigators;
    return value;
  };

  onChange = (value: InvestigationCardInputValue | GradingCardInputValue) => {
    const values = this.state.values;
    const i = values.findIndex((it) => it.id === value.id);
    if (i === -1) return;
    values[i] = value;
    this.setState({ values: [...values] });
  };

  disbableSubmitButton = () => {
    if (this.state.loading) return true;

    if (this.state.data === null) return true;
    return false;
  };

  onSubmit = () => {
    this.setState({ loading: true }, async () => {
      const { model, updateModel } = this.props;
      const ro: PerformGradingRequestObject = {
        case_id: model.getId(),
        allocations: this.state.values.map((it) => ({
          claim_analyst: it.claimAnalystId,
          team: it.investigators.map((it) => it.id),
          allocation_hygiene: it.allocationHygiene,
          report_quality: it.reportQuality,
          outcome: it.outcome,
          tat: it.tat,
          feedback: it.feedback,
        })),
      };

      const result = await this.caseRepo.performGrading(ro);
      if (result instanceof Result.Success) {
        this.setState({ loading: false }, () => {
          const c = result.data as CompleteCase;
          toast.success("Successfully performed Grading");
          if (updateModel) updateModel(c);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  updateInvestigatorsInfo = () => {
    this.setState({ loading: true }, async () => {
      const { model, updateModel } = this.props;
      const ro: PerformQualityCheckRequestObject = {
        case_id: model.getId(),
        allocations: this.state.values.map((it) => {
          return {
            head_id: it.claimAnalystId,
            team: it.investigators.map((inv) => ({
              allocation_id: inv.allocationId,
              investigator_id: inv.id,
              case_portion: inv.capping,
              outcome: inv.outcome,
              allowance: inv.allowance,
              investigation_location: {
                state: inv.investigationLocation?.getState(),
                district: inv.investigationLocation?.getDistrict(),
              },
              document_expense: inv.documentExpense,
              extra_expense: inv.extraExpense,
              approved_expense: inv.approvedExpense,
            })),
            grade_report: {
              allocation_hygiene: it.allocationHygiene,
              report_quality: it.reportQuality,
              outcome: it.outcome,
              tat: it.tat,
            },
            feedback: it.feedback,
          };
        }),
      };

      const result = await this.caseRepo.updateQualityCheck(ro);
      if (result instanceof Result.Success) {
        this.setState({ loading: false }, () => {
          const c = result.data as CompleteCase;
          toast.success("Successfully updated");
          if (updateModel) updateModel(c);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  render() {
    const { model } = this.props;

    let allocations = null;
    let grading = null;
    if (this.state.data !== null) {
      allocations = this.state.data.getAllocations().map((it, i) => {
        return (
          <InvestigationCard
            key={i}
            model={it}
            value={this.state.values.find((v) => v.id === `${i}`)!}
            onChange={this.onChange}
            newData={model}
          />
        );
      });
      grading = this.state.data.getAllocations().map((it, i) => {
        return (
          <GradingCard
            key={i}
            model={it}
            value={this.state.values.find((v) => v.id === `${i}`)!}
            onChange={this.onChange}
          />
        );
      });
    }

    let report = null;
    if (this.state.data !== null) report = this.state.data.getReport();

    let finalReport = this.props.model.getFinalReport();

    let repudiationEvidence: URLResource[] = [];
    if (this.state.data !== null)
      repudiationEvidence = this.state.data.getRepudiationEvidences();

    let queryTriggerDocument: any;
    if (this.state.data !== null)
      queryTriggerDocument = this.state.data.getQueryTriggerDocument();
    return (
      <Segment basic loading={this.state.loading}>
        {/* report */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            {/* report */}
            <Button
              as="a"
              href={report && report.getURL()}
              disabled={report === null}
              target="_blank"
              icon
              primary
              labelPosition="left"
              size="tiny"
            >
              <Icon name="file alternate outline" />
              View Report
            </Button>
            {/* report */}

            {/* final report */}
            <Button
              as="a"
              href={finalReport && finalReport.getURL()}
              disabled={finalReport === null}
              target="_blank"
              icon
              primary
              labelPosition="left"
              size="tiny"
            >
              <Icon name="file alternate outline" />
              View Final Report
            </Button>
            {/* final report */}

            {/* repudiation evidence */}
            <Button
              icon
              primary
              labelPosition="left"
              size="tiny"
              disabled={
                this.state.repudiationEvidencePopupOpen ||
                repudiationEvidence.length === 0
              }
              onClick={() =>
                this.setState({ repudiationEvidencePopupOpen: true })
              }
            >
              <Icon name="file alternate outline" />
              {repudiationEvidence.length === 0
                ? "No Repudiation Evidence"
                : "View Repudiation Evidence"}
            </Button>
            {/* repudiation evidence */}

            {/*<Button*/}
            {/*    content= {`${model.getType().getTitle()}`}*/}
            {/*    label={{ as: 'a', basic: true, pointing: 'right', content: 'Case Type' }}*/}
            {/*    labelPosition='left'*/}
            {/*/>*/}
            <Label basic size={"large"} color={"blue"}>
              Case Type{" "}
              <Label.Detail>{model.getType().getTitle()}</Label.Detail>
            </Label>
            <Label basic size={"large"} color={"blue"}>
              Case Outcome <Label.Detail>{model.getOutcome()}</Label.Detail>
            </Label>
            {/* repudiation_ground */}
            {model.getOutcome() === "Repudiation" && <Label basic size={'large'} color={'blue'}>
              Repudiation Ground <Label.Detail>{model.getRepudiationGround()}</Label.Detail>
            </Label>}

            {queryTriggerDocument?.getURL() !== null && <Button
              as='a'
              href={queryTriggerDocument && queryTriggerDocument.getURL()}
              disabled={queryTriggerDocument === null}
              target='_blank'
              icon
              primary
              labelPosition='left'
              size='tiny'>
              <Icon name='file alternate outline' />
              Query Trigger Document
            </Button>}

            {this.state.repudiationEvidencePopupOpen &&
              ReactDOM.createPortal(
                <FilesPopup
                  title="Repudiation Evidence"
                  onClose={() => this.setState({ repudiationEvidencePopupOpen: false })}
                  files={repudiationEvidence} allocationId={model.getId()}        />,
                this.portalRoot!
              )}
          </div>
        </div>

        <Segment>
          {allocations}

          <Button
            icon
            labelPosition="left"
            primary
            onClick={this.updateInvestigatorsInfo}
          >
            <Icon name="check circle" />
            Update Information
          </Button>
        </Segment>
        <h3>Case Grading</h3>
        {grading}

        <Button
          icon
          labelPosition="left"
          positive
          size="large"
          floated="right"
          disabled={this.disbableSubmitButton()}
          onClick={this.onSubmit}
        >
          <Icon name="check circle" />
          Done - Grading
        </Button>
      </Segment>
    );
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Case.grade}>
      <ActionGrading {...props} />
    </RequirePermission>
  );
};
