  import User from './User'
import DomainModel from './DomainModel'
import URLResource from './URLResource'
import Location from './Location'

export interface RemovedEvidence{
  getExtension(): unknown
  getURL(): RequestInfo
  type:string,
  document:URLResource[]
}

export default class AllocatedInvestigator extends DomainModel {
  static getId() {
    throw new Error("Method not implemented.")
    
  }
  getClaimNumber: any
  claimNumber: string | undefined
  constructor(
    private id: string,
  
    
    private caseId: string,
    private investigator: User,
    private investigationSpecifications: string[],
    private allocatedBy: User,
    private allocatedOn: string,
    private active: boolean,
    private evidence: URLResource[],
    private outcome: string | null,
    private casePortion: number | null,
    private invesitgationLocation: Location | null,
    private allowance: number,
    private documentExpense: number,
    private documentExpenseReceipt: URLResource[],
    private approvedExpense: number,
    private approvedExpenseReceipt: URLResource[],
    private extraExpense: number,
    private extraExpenseReceipt: URLResource[],
    //private removedEvidencByInvestigator: URLResource[]
    private removedEvidence:RemovedEvidence[]
  ) {
    super()
  }
  getId = () => this.id
  // getClaimNumber = () => this.claimNumber;
  getCaseId = () => this.caseId
  getInvestigator = () => this.investigator
  getInvestigationSpecifications = () => this.investigationSpecifications
  getAllocatedBy = () => this.allocatedBy
  getAllocatedOn = () => this.allocatedOn
  getActive = () => this.active
  getEvidence = () => this.evidence
  getOutcome = () => this.outcome
  getCasePortion = () => this.casePortion
  getInvestigationLocation = () => this.invesitgationLocation
  getAllowance = () => this.allowance
  getDocumentExpense = () => this.documentExpense
  getDocumentExpenseReceipt = () => this.documentExpenseReceipt
  getApprovedExpense = () => this.approvedExpense
  getApprovedExpenseReceipt = () => this.approvedExpenseReceipt
  getExtraExpense = () => this.extraExpense
  getExtraExpenseReceipt = () => this.extraExpenseReceipt
  // getRemovedEvidencByInvestigator = () => this.removedEvidencByInvestigator
  getRemovedEvidence=()=>this.removedEvidence

}
