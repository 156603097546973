import DomainModel from './DomainModel'
import User from './User'
import AllocatedInvestigator from './AllocatedInvestigator'
import Grade from './Grade'

class AllocatedClaimAnalyst extends DomainModel {
  getClaimNumber: any
  getInvestigator() {
    throw new Error("Method not implemented.")
  }
  getEvidence(): import("./URLResource").default[] {
    throw new Error("Method not implemented.")
  }
  getDocumentExpenseReceipt(): import("./URLResource").default[] {
    throw new Error("Method not implemented.")
  }
  getExtraExpenseReceipt(): import("./URLResource").default[] {
    throw new Error("Method not implemented.")
  }
  getApprovedExpenseReceipt(): import("./URLResource").default[] {
    throw new Error("Method not implemented.")
  }
  getId: any
  constructor(private grade: Grade | null, private feedback: string | null, private claimAnalyst: User, private allocatedInvestigators: AllocatedInvestigator[]) {
    super()
  }

  getGrade = () => this.grade
  getFeedback = () => this.feedback
  getClaimAnalyst = () => this.claimAnalyst
  getAllocatedInvestigators = () => this.allocatedInvestigators
}

export default AllocatedClaimAnalyst
