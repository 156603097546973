import { AuthState, AuthActionTypes, SET_AUTH, AUTH_STATE_INITIALIZING } from './types';

const initialState: AuthState = {
  initializing: false,
  user: null,
};

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case SET_AUTH:
      return {
        initializing: false,
        user: action.payload,
      };
    case AUTH_STATE_INITIALIZING:
      return {
        initializing: true,
        user: null,
      };
    default:
      return state;
  }
}
