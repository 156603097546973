import React, { useState, useEffect } from "react";
import {
  DropdownItemProps,
  Form,
} from "semantic-ui-react";
import axios from "axios";
import LocalStorage from "../../../../lib/LocalStorage";
import ClientRepository from "../../../../common/repository/ClientRepository";
import Result from "../../../../common/repository/Result";
import { toast } from "../../../common/Toast";
import DateTimePicker from "../../../app/DateTimePicker";
import config from "../../../../common/repository/config";

function PaymentTemplateDownloadForm({ onSuccess }: Props) {
  const [clientOptions, setClientOptions] = useState<DropdownItemProps[]>([]);
  const [clientId, setClientId] = useState<string>("");
  const [docReceived, setDocReceived] = useState(false);
  const [tds, setTds] = useState<number | undefined>(undefined);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  const clientRepo = new ClientRepository();

  // Load client data on component mount
  useEffect(() => {
    if (clientOptions.length === 0) {
      loadEssentialData();
    }
  }, []);

  const loadEssentialData = async () => {
    const userData = await clientRepo.getClients({});
    if (userData instanceof Result.Success) {
      setClientOptions(
        userData.data.items.map((it) => ({
          text: it.getName(),
          value: it.getId(),
          key: it.getId(),
        }))
      );
    } else {
      const message = userData.message || "Could not load Clients";
      toast.error(message);
    }
  };

  // TDS validation to ensure numeric input
  const handleTdsChange = (_: any, { value }: any) => {
    const numericValue = Number(value);
    if (!isNaN(numericValue)) {
      setTds(numericValue);
    }
  };

  const downloadTemplate = async () => {
    try {
      toast.success("Download will start shortly!");
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      const response = await axios({
        url: `${config.apiBaseURL}/billing/payment/template`,
        method: "POST",
        data: {
          client_id: clientId,
          docReceived: docReceived,
          tds: tds,
          from: fromDate.length > 0 ? fromDate : undefined,
          to: toDate.length > 0 ? toDate : undefined,
        },
        responseType: "blob",
        headers: { "x-access-token": token },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "paymentTemplate.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (onSuccess) onSuccess();
    } catch (error) {
      toast.error("Failed to download the template. Please try again.");
      console.error("Download error: ", error);
    }
  };

  return (
    <Form>
      <Form.Dropdown
        label="Choose Client"
        search
        selection
        placeholder="Select Client"
        options={clientOptions}
        value={clientId}
        onChange={(_, { value }) => setClientId(value as string)}
      />
      <Form.Input
        label="TDS (%)"
        type="number"
        placeholder="Enter TDS"
        value={tds ?? ""}
        onChange={handleTdsChange}
      />
      <Form.Input label="From Date">
        <DateTimePicker
          placeholder="Select From Date"
          type="date"
          value={fromDate}
          onChange={(val) => setFromDate(val)}
        />
      </Form.Input>
      <Form.Input label="To Date" disabled={fromDate.length === 0}>
        <DateTimePicker
          placeholder="Select To Date"
          type="date"
          value={toDate}
          onChange={(val) => setToDate(val)}
        />
      </Form.Input>

      <Form.Button
        positive
        disabled={clientId.length === 0 || (tds ?? 0) <= 0}
        onClick={downloadTemplate}
      >
        Download
      </Form.Button>
    </Form>
  );
}

interface Props {
  onSuccess?(): void;
}

export default PaymentTemplateDownloadForm;
