import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Segment, Header, Modal, Button, Icon, Label } from "semantic-ui-react";
import PushBackForm from "./PushBackForm";
import CompleteCase from "../../../../../../models/CompleteCase";
import InvestigationCard, { InvestigationCardInputValue, } from "./InvestigationCard";
import AllocatedClaimAnalyst from "../../../../../../models/AllocatedClaimAnalyst";
import CaseRepository, { PerformQualityCheckRequestObject, } from "../../../../../../common/repository/CaseRepository";
import Result from "../../../../../../common/repository/Result";
import { toast } from "../../../../../common/Toast";
import QCData from "../../../../../../models/QCData";
import FilesPopup from "../../FilesPopup";
import URLResource from "../../../../../../models/URLResource";
import RequirePermission from "../../../../../base/RequirePermission";
import permissions from "../../../../../../common/permissions";
import GradingCard, { GradingCardInputValue } from "../ActionGrading/GradingCard";

interface Props {
  model: CompleteCase;
  updateModel?(c: CompleteCase): void;
}

interface State {
  loading: boolean;
  pushbackModalOpen: boolean;
  repudiationEvidencePopupOpen: boolean;
  qcData: QCData | null;
  values: InvestigationCardInputValue[];
}

class ActionQualityCheck extends Component<Props, State> {
  private caseRepo = new CaseRepository();
  private portalRoot: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      pushbackModalOpen: false,
      repudiationEvidencePopupOpen: false,
      qcData: null,
      values: [],
    };
  }

  componentDidMount() {
    this.getQCData();
    this.portalRoot = document.getElementsByTagName("body")[0];
  }

  getQCData = () => {
    this.setState({ loading: true }, async () => {
      const { model } = this.props;
      const result = await this.caseRepo.getQCData({ case_id: model.getId() });

      if (result instanceof Result.Success) {
        const qcData = result.data;
        const values = qcData
          .getAllocations()
          .map((it, i) => this.mapAllocatedClaimAnalystToValue(`${i}`, it));
        this.setState({ loading: false, values, qcData });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  mapAllocatedClaimAnalystToValue = (
    fakeId: string,
    allocatedClaimAnalyst: AllocatedClaimAnalyst
  ) => {
    const value = { ...InvestigationCard.initialValue };

    value.id = fakeId;
    value.claimAnalystId = allocatedClaimAnalyst.getClaimAnalyst().getId();
    const investigators = allocatedClaimAnalyst
      .getAllocatedInvestigators()
      .map((it) => {
        const id = it.getInvestigator().getId();
        const allocationId = it.getId();
        const casePortion = it.getCasePortion();
        const investigationOutcome = it.getOutcome();
        const investigationLocation = it.getInvestigationLocation();
        const allowance = it.getAllowance();
        const documentExpense = it.getDocumentExpense();
        const extraExpense = it.getExtraExpense();
        const approvedExpense = it.getApprovedExpense();

        let capping = "";
        if (casePortion !== null) capping = casePortion.toString();

        let outcome = "";
        if (investigationOutcome !== null) outcome = investigationOutcome;
        return {
          id,
          allocationId,
          capping,
          outcome,
          investigationLocation,
          allowance,
          documentExpense,
          extraExpense,
          approvedExpense,
        };
      });

    value.investigators = investigators;
    return value;
  };

  onChange = (value: InvestigationCardInputValue | GradingCardInputValue) => {
    const values = this.state.values;
    const i = values.findIndex((it) => it.id === value.id);
    if (i === -1) return;
    values[i] = value;
    this.setState({ values: [...values] });
  };

  disbableSubmitButton = () => {
    if (this.state.loading) return true;

    if (this.state.qcData === null) return true;

    let disable = false;
    this.state.values.forEach((it) => {
      it.investigators.forEach((inv) => {
        if (inv.capping === "") disable = true;
      });
    });
    return disable;
  };

  onSubmit = () => {
    this.setState({ loading: true }, async () => {
      const { model, updateModel } = this.props;
      const ro: PerformQualityCheckRequestObject = {
        case_id: model.getId(),
        allocations: this.state.values.map((it) => {
          return {
            head_id: it.claimAnalystId,
            team: it.investigators.map((inv) => ({
              allocation_id: inv.allocationId,
              investigator_id: inv.id,
              case_portion: inv.capping,
              outcome: inv.outcome,
              allowance: inv.allowance,
              investigation_location: {
                state: inv.investigationLocation?.getState(),
                district: inv.investigationLocation?.getDistrict(),
              },
              document_expense: inv.documentExpense,
              extra_expense: inv.extraExpense,
              approved_expense: inv.approvedExpense,
            })),
            grade_report: {
              allocation_hygiene: it.allocationHygiene,
              report_quality: it.reportQuality,
              outcome: it.outcome,
              tat: it.tat,
            },
            feedback: it.feedback,
          };
        }),
      };

      const result = await this.caseRepo.performQualityCheck(ro);
      if (result instanceof Result.Success) {
        this.setState({ loading: false }, () => {
          const c = result.data as CompleteCase;
          toast.success("Successfully performed QC");
          if (updateModel) updateModel(c);
        });
      } else {
        this.setState({ loading: false }, () => {
          const message = result.message || "Something went wrong";
          toast.error(message);
        });
      }
    });
  };

  render() {
    const { model, updateModel } = this.props;

    let allocations = null;
    let grading = null;
    if (this.state.qcData !== null) {
      allocations = this.state.qcData.getAllocations().map((it, i) => {
        return (
          <InvestigationCard
            key={i}
            model={it}
            value={this.state.values.find((v) => v.id === `${i}`)!}
            onChange={this.onChange}
            newData={model}
          />
        );
      });
      grading = this.state.qcData.getAllocations().map((it, i) => {
        return (
          <GradingCard
            key={i}
            model={it}
            value={this.state.values.find((v) => v.id === `${i}`)!}
            onChange={this.onChange}
          />
        );
      });
    }
    { console.log(this.state.qcData, "this.state.qcData") }
    let report = null;
    if (this.state.qcData !== null) report = this.state.qcData.getReport();



    let queryTriggerDocument = null;
    if (this.state.qcData !== null) queryTriggerDocument = this.state.qcData.getQueryTriggerDocument();


    let repudiationEvidence: URLResource[] = [];
    if (this.state.qcData !== null)
      repudiationEvidence = this.state.qcData.getRepudiationEvidences();


    let ReinvestigationReport = null;
    let ReinvestigationFilalReports = null;
    let ReinvestigationRepudiationEvidence: any[] = [];
    let ReinvestigationQueryTriggerDocument = null;

    if (this.props.model.getReinvestigation()) {
      ReinvestigationReport = this.props.model.getReport();
      ReinvestigationFilalReports = this.props.model.getFinalReport()

      console.log(model.getQueryTriggerDocument(), "updsauyrer")
      if (model.getRepudiationEvidence())
        ReinvestigationRepudiationEvidence = this.props.model.getRepudiationEvidence();

      if (this.props.model.getQueryTriggerDocument() !== null) ReinvestigationQueryTriggerDocument = this.props.model.getQueryTriggerDocument();
    }




    return (
      <Segment basic loading={this.state.loading}>


        {this.props.model.getReinvestigation() && <div>
          <Header as="h3">
            Re-investigation Reports
          </Header>
          {/* report */}
          <Button
            as="a"
            href={ReinvestigationReport && ReinvestigationReport.getURL()}
            disabled={ReinvestigationReport === null}
            target="_blank"
            icon
            primary
            labelPosition="left"
            size="tiny"
          >
            <Icon name="file alternate outline" />
            View Report
          </Button>
          {/* report */}

          {/* repudiation evidence */}
          {model.getOutcome() == "Repudiation" && <Button
            icon
            primary
            labelPosition="left"
            size="tiny"
            disabled={
              this.state.repudiationEvidencePopupOpen ||
              ReinvestigationRepudiationEvidence.length === 0
            }
            onClick={() =>
              this.setState({ repudiationEvidencePopupOpen: true })
            }
          >
            <Icon name="file alternate outline" />
            {ReinvestigationRepudiationEvidence.length === 0
              ? "No Repudiation Evidence"
              : "View Repudiation Evidence"}
          </Button>}
          {/* repudiation evidence */}
          {/* <Label basic size={"large"} color={"blue"}>
            Case Type{" "}
            <Label.Detail>{model.getType().getTitle()}</Label.Detail>
          </Label>
          <Label basic size={"large"} color={"blue"}>
            Case Outcome <Label.Detail>{model.getOutcome()}</Label.Detail>
          </Label> */}
          {model.getRepudiationGround() && model.getOutcome() == "Repudiation" && <Label basic size={"large"} color={"blue"}>
            Repudation Ground <Label.Detail>{model.getRepudiationGround()}</Label.Detail>
          </Label>}
          {/* Final Report */}
          {
            ReinvestigationFilalReports?.getURL() !== null &&
            <Button
              as="a"
              href={ReinvestigationFilalReports && ReinvestigationFilalReports.getURL()}
              disabled={ReinvestigationFilalReports === null}
              target="_blank"
              icon
              primary
              labelPosition="left"
              size="tiny"
            >
              <Icon name="file alternate outline" />
              Final Report
            </Button>}
          {/* Repudiation Evidence */}
          {this.state.repudiationEvidencePopupOpen &&
            ReactDOM.createPortal(
              <FilesPopup
                title="Repudiation Evidence"
                onClose={() => this.setState({ repudiationEvidencePopupOpen: false })}
                files={repudiationEvidence} allocationId={model.getId()}      />,
              this.portalRoot!
            )}

          {/* Query triger Document */}
          {ReinvestigationQueryTriggerDocument?.getURL() !== null && model.getOutcome() == "Inconclusive" && <Button
            as="a"
            href={ReinvestigationQueryTriggerDocument && ReinvestigationQueryTriggerDocument.getURL()}
            disabled={ReinvestigationQueryTriggerDocument === null}
            target="_blank"
            icon
            primary
            labelPosition="left"
            size="tiny"
          >
            <Icon name="file alternate outline" />
            Query Trigger Document
          </Button>}
        </div>
        }
        <Header as="h3">
          Quality Check
          {/*<Header.Subheader>*/}
          {/*    <b>Case Type:</b> {model.getType().getTitle()}*/}
          {/*    &nbsp;&nbsp;&nbsp;*/}
          {/*    <b>Case Outcome:</b> {model.getOutcome()}*/}
          {/*</Header.Subheader>*/}
        </Header>

        {/* report */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            {/* report */}
            <Button
              as="a"
              href={report && report.getURL()}
              disabled={report === null}
              target="_blank"
              icon
              primary
              labelPosition="left"
              size="tiny"
            >
              <Icon name="file alternate outline" />
              View Report
            </Button>
            {/* report */}

            {/* repudiation evidence */}
            {model.getRepudiationGround() && model.getOutcome() == "Repudiation" && <Button
              icon
              primary
              labelPosition="left"
              size="tiny"
              disabled={
                this.state.repudiationEvidencePopupOpen ||
                repudiationEvidence.length === 0
              }
              onClick={() =>
                this.setState({ repudiationEvidencePopupOpen: true })
              }
            >
              <Icon name="file alternate outline" />
              {repudiationEvidence.length === 0
                ? "No Repudiation Evidence"
                : "View Repudiation Evidence"}
            </Button>}
            {/* repudiation evidence */}
            <Label basic size={"large"} color={"blue"}>
              Case Type{" "}
              <Label.Detail>{model.getType().getTitle()}</Label.Detail>
            </Label>
            <Label basic size={"large"} color={"blue"}>
              Case Outcome <Label.Detail>{model.getOutcome()}</Label.Detail>
            </Label>
            {model.getRepudiationGround() && model.getOutcome() == "Repudiation" && <Label basic size={"large"} color={"blue"}>
              Repudation Ground <Label.Detail>{model.getRepudiationGround()}</Label.Detail>
            </Label>}

            {queryTriggerDocument?.getURL() !== null && model.getOutcome() == "Inconclusive" && <Button
              as="a"
              href={queryTriggerDocument && queryTriggerDocument.getURL()}
              disabled={queryTriggerDocument === null}
              target="_blank"
              icon
              primary
              labelPosition="left"
              size="tiny"
            >
              <Icon name="file alternate outline" />
              Query Trigger Document
            </Button>}

            {this.state.repudiationEvidencePopupOpen &&
              ReactDOM.createPortal(
                <FilesPopup
                  title="Repudiation Evidence"
                  onClose={() => this.setState({ repudiationEvidencePopupOpen: false })}
                  files={repudiationEvidence} allocationId={model.getId()}               />,
                this.portalRoot!
              )}
          </div>

          {/* Push Back Modal */}
          <Modal
            open={this.state.pushbackModalOpen}
            onClose={() => this.setState({ pushbackModalOpen: false })}
            size="tiny"
            closeOnDimmerClick={false}
            closeOnEscape={false}
            closeIcon
            trigger={
              <RequirePermission permission={permissions.Case.pushback}>
                <Button
                  icon
                  negative
                  labelPosition="left"
                  onClick={() => this.setState({ pushbackModalOpen: true })}
                >
                  <Icon name="ban" />
                  Push Back
                </Button>
              </RequirePermission>
            }
          >
            <Modal.Header>Push Back</Modal.Header>
            <Modal.Content>
              <PushBackForm
                model={this.props.model}
                onSuccess={(it) => {
                  this.setState({ pushbackModalOpen: false });
                  if (updateModel) updateModel(it as CompleteCase);
                }}
              />
            </Modal.Content>
          </Modal>
          {/* Push Back Modal */}
        </div>
        {allocations}
        {/* <h3>Case Grading</h3>
        {grading} */}

        <Button
          icon
          labelPosition="left"
          positive
          size="large"
          floated="right"
          disabled={this.disbableSubmitButton()}
          onClick={this.onSubmit}
        >
          <Icon name="check circle" />
          Done - Quality Check
        </Button>
      </Segment>
    );
  }
}

export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Case.check}>
      <ActionQualityCheck {...props} />
    </RequirePermission>
  );
};
